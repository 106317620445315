.max-path {
    fill: none;
    stroke: var(--s-color-text-primary);
    stroke-width: 1;

    @media (forced-colors: active) {
        stroke: currentColor;
    }
}

.max-points {
    fill: var(--s-color-text-primary);
    font-weight: var(--g-font-weight-regular);
    font-family: var(--g-font-family-primary);

    @media (forced-colors: active) {
        fill: currentColor;
    }
}

.min-path {
    fill: none;
    stroke: var(--s-color-text-secondary);
    stroke-width: 1;

    @media (forced-colors: active) {
        stroke: currentColor;
    }
}

.min-points {
    fill: var(--s-color-text-secondary);
    font-weight: var(--g-font-weight-regular);
    font-family: var(--g-font-family-primary);

    @media (forced-colors: active) {
        fill: currentColor;
    }
}
