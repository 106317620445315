.modal-image {
    display: flex;
    aspect-ratio: 16/9;
    margin-bottom: var(--g-spacing-lg);
    img {
        object-fit: cover;
        width: 100%;
        height: 100%;
        object-position: center;
    }
}
