@use "@wojo/ui/mixins" as *;

.banner {
    width: 100%;
    position: fixed;
    bottom: 0;
    left: 0;
    box-sizing: border-box;
    background-color: var(--s-color-surface-secondary);
    padding: var(--g-spacing-sm) var(--g-spacing-lg);
    z-index: 10;
    box-shadow: var(--s-shadow-up);

    @include breakpoint-md {
        padding-left: var(--g-spacing-xl);
        padding-right: var(--g-spacing-xl);
    }

    @include breakpoint-lg {
        display: none;
    }

    &--hidden {
        display: none;
    }
}
