.container {
    padding: var(--g-spacing-lg) var(--g-spacing-md) var(--g-spacing-md);
}

.header-wrapper {
    display: grid;
    grid-template-columns: 1fr auto;
    justify-content: space-between;
    align-items: start;
}
