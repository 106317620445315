@use "../../styles/mixins" as *;
@include layer(ui) {
    .tablist {
        --list-padding: var(--list-padding-xs);
        --tab-padding: var(--tab-padding-xs);
        --tab-spacing: var(--tab-spacing-xs);
        --tab-width: var(--tab-width-xs);

        @include breakpoint-sm {
            --list-padding: var(--list-padding-sm);
            --tab-padding: var(--tab-padding-sm);
            --tab-spacing: var(--tab-spacing-sm);
            --tab-width: var(--tab-width-sm);
        }
        @include breakpoint-md {
            --list-padding: var(--list-padding-md);
            --tab-padding: var(--tab-padding-md);
            --tab-spacing: var(--tab-spacing-md);
            --tab-width: var(--tab-width-md);
        }
        @include breakpoint-lg {
            --list-padding: var(--list-padding-lg);
            --tab-padding: var(--tab-padding-lg);
            --tab-spacing: var(--tab-spacing-lg);
            --tab-width: var(--tab-width-lg);
        }
        @include breakpoint-xl {
            --list-padding: var(--list-padding-xl);
            --tab-padding: var(--tab-padding-xl);
            --tab-spacing: var(--tab-spacing-xl);
            --tab-width: var(--tab-width-xl);
        }

        display: grid;
        grid-auto-flow: column;
        overflow-x: auto;
        position: relative;
        scrollbar-width: none;

        &::-webkit-scrollbar {
            display: none;
            width: 0;
        }

        &.indicator-shadow {
            --indicator-list-padding: 12px;

            margin-top: calc(var(--indicator-list-padding) * -1);
            padding-top: var(--indicator-list-padding);
        }

        &.list-padding--default {
            padding-inline: var(--g-spacing-md);
        }

        &.list-padding--custom {
            padding-inline: var(--list-padding);
        }

        &.tab-background--transparent {
            border-bottom: var(--g-border-width-xs) solid
                var(--s-color-separator-standard);
        }

        &.tab-spacing--default {
            gap: var(--g-spacing-md);

            @include for-tablet-up {
                gap: var(--g-spacing-xl);
            }
        }

        &.tab-spacing--custom {
            gap: var(--tab-spacing);
        }

        &.tab-width--auto {
            grid-auto-columns: max-content;
        }

        &.tab-width--custom {
            grid-auto-columns: var(--tab-width, max-content);
        }

        &.tab-width--full {
            grid-auto-columns: 1fr;
        }
    }

    .tab {
        display: grid;
        grid-template: 1fr / 1fr;
    }

    .tab-button {
        align-items: center;
        border: none;
        box-sizing: border-box;
        color: var(--s-color-tab-foreground-default);
        cursor: pointer;
        display: flex;
        font-family: var(--g-font-family-primary);
        font-size: var(--g-font-size-400);
        font-weight: var(--g-font-weight-medium);
        grid-area: 1 / 1;
        justify-content: center;
        padding: 0;
        position: relative;
        transition-duration: var(--g-duration-xshort);
        transition-property: background, color, border-color;
        transition-timing-function: ease-in-out;
        z-index: 0;

        &:active,
        &:hover,
        &:focus-visible {
            color: var(--s-color-tab-foreground-active);

            @media (forced-colors: active) {
                color: Highlight;
            }
        }

        &[aria-selected="true"] {
            color: var(--s-color-tab-foreground-selected);
            cursor: default;
            z-index: 0;

            &:active,
            &:hover {
                color: var(--s-color-tab-foreground-selected);
            }

            &:focus-visible {
                color: var(--s-color-tab-foreground-active);

                @media (forced-colors: active) {
                    border-color: Highlight;
                    color: Highlight;
                }
            }
        }

        .indicator--bottom &,
        .indicator--top & {
            &::before {
                border: 0 solid var(--s-color-tab-border-selected);
                content: "";
                height: 0;
                left: 0;
                position: absolute;
                width: 100%;
            }

            &:focus-visible {
                outline: none;

                &::before {
                    border-color: var(--s-color-tab-foreground-active);
                    border-top-width: var(--g-border-width-sm);

                    @media (forced-colors: active) {
                        border-color: Highlight;
                    }
                }
            }

            &[aria-selected="true"]::before {
                border-top-width: var(--g-border-width-md);
            }
        }

        .indicator--bottom.indicator-shadow
            &[aria-selected="true"]
            + .tab-shadow {
            box-shadow: var(--s-shadow-down);
        }

        .indicator--top.indicator-shadow &[aria-selected="true"] + .tab-shadow {
            box-shadow: var(--s-shadow-up);
        }

        .tab-background--opaque & {
            background: var(--s-color-tab-background-default);
            border: var(--g-border-width-xs) solid transparent;
            border-bottom-color: var(--s-color-separator-standard);

            @media (forced-colors: active) {
                &::after {
                    border-right: var(--g-border-width-xs) solid transparent;
                    content: "";
                    height: 100%;
                    right: calc(var(--g-border-width-xs) * -1);
                    position: absolute;
                    width: 0;
                }
            }

            @media (prefers-reduced-motion: reduce) {
                transition: none;
            }

            &:active,
            &:hover,
            &:focus-visible {
                background: var(--s-color-tab-background-active);

                @media (forced-colors: active) {
                    &::after {
                        border-color: Highlight;
                    }
                }
            }

            &[aria-selected="true"] {
                background: var(--s-color-tab-background-selected);
                border-bottom-color: transparent;

                @media (forced-colors: active) {
                    border-bottom-color: Canvas;
                }

                @media (prefers-reduced-motion: reduce) {
                    transition: none;
                }

                &:focus-visible {
                    background: var(--s-color-tab-background-active);
                }
            }
        }

        .tab-background--opaque .tab:not(:first-of-type) & {
            border-left-color: var(--s-color-separator-standard);
        }

        .tab-background--opaque .tab:not(:last-of-type) & {
            border-right: none;
        }

        .tab-background--opaque.indicator--bottom &::before,
        .tab-background--opaque.indicator--top &::before {
            left: calc(var(--g-border-width-xs) * -1);
            width: calc(100% + calc(var(--g-border-width-xs) * 2));
        }

        .tab-background--opaque.indicator--bottom &::before {
            bottom: calc(var(--g-border-width-xs) * -1);
        }

        .tab-background--opaque.indicator--top &::before {
            top: calc(var(--g-border-width-xs) * -1);
        }

        .tab-background--opaque.tab-padding--default & {
            line-height: 120%;
            min-height: 60px;
            padding-block: calc(
                var(--g-border-width-md) - var(--g-border-width-xs)
            );
            padding-left: calc(var(--g-spacing-sm) - var(--g-border-width-xs));
            padding-right: var(--g-spacing-sm);
        }

        .tab-background--opaque.tab-padding--custom & {
            padding: calc(var(--tab-padding) - var(--g-border-width-xs));
        }

        .tab-background--opaque.tab-spacing--default & {
            border-top-color: var(--s-color-separator-standard);

            @media (forced-colors: active) {
                border-top-color: transparent;
            }
        }

        .tab-background--opaque.tab-spacing--default .tab &,
        .tab-background--opaque.tab-spacing--custom .tab & {
            border-left-color: var(--s-color-separator-standard);
            border-right: var(--g-border-width-xs) solid
                var(--s-color-separator-standard);

            @media (forced-colors: active) {
                border-color: transparent;
            }
        }

        .tab-background--transparent & {
            background: transparent;
        }

        .tab-background--transparent.indicator--bottom &::before,
        .tab-background--transparent.indicator--top &::before {
            left: 0;
            width: 100%;
        }

        .tab-background--transparent.indicator--bottom &::before {
            bottom: 0;
        }

        .tab-background--transparent.indicator--top &::before {
            top: 0;
        }

        .tab-background--transparent.tab-padding--default & {
            line-height: var(--g-line-height-400);
            min-height: 60px;
        }

        .tab-background--transparent.tab-padding--custom & {
            padding: var(--tab-padding);
        }
    }

    .adornment {
        font-weight: var(--g-font-weight-light);
        margin-left: var(--g-spacing-xs);
    }

    .tab-shadow {
        grid-area: 1 / 1;
        transition-duration: var(--g-duration-xshort);
        transition-property: box-shadow;
        transition-timing-function: ease-in-out;

        @media (prefers-reduced-motion: reduce) {
            transition: none;
        }
    }

    .tabpanel {
        animation: animatePanel var(--g-duration-xshort) ease-in-out;
        opacity: 1;
        visibility: visible;

        @media (prefers-reduced-motion: reduce) {
            animation: none;
        }

        &[hidden] {
            opacity: 0;
            visibility: hidden;
        }
    }

    .animate-selection {
        &::before {
            animation-duration: var(--g-duration-xshort);
            animation-fill-mode: forwards;
            animation-name: animateCursor;
            animation-timing-function: ease-in-out;
            background: var(--s-color-tab-border-selected);
            content: "";
            height: var(--g-border-width-md);
            left: 0;
            position: absolute;
            z-index: 1;

            @media (forced-colors: active) {
                background: Highlight;
            }

            @media (prefers-reduced-motion: reduce) {
                content: none;
            }
        }

        .tab-button[aria-selected="true"]:not(:focus-visible)::before {
            visibility: hidden;

            @media (prefers-reduced-motion: reduce) {
                visibility: visible;
            }
        }

        &.indicator--bottom {
            &::before {
                bottom: 0;
            }
        }

        &.indicator--top {
            &::before {
                top: 0;
            }

            &.indicator-shadow::before {
                top: var(--indicator-list-padding);
            }
        }
    }

    @keyframes animateCursor {
        from {
            transform: translateX(var(--cursor-from));
            width: var(--cursor-from-width);
        }
        to {
            transform: translateX(var(--cursor-to));
            width: var(--cursor-to-width);
        }
    }

    @keyframes animatePanel {
        from {
            opacity: 0;
            visibility: hidden;
        }
        to {
            opacity: 1;
            transition: opacity var(--g-duration-xshort) ease-in-out;
            visibility: visible;
        }
    }
}
