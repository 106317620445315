@use "@wojo/ui/mixins" as *;

.nav {
    background-color: var(--c-switcher-background);
    border-radius: var(--c-switcher-background-border-radius);
    border-style: solid;
    border-color: var(--c-switcher-background-border-color);
    border-width: var(--g-border-width-xs);
    margin: 0 var(--g-spacing-md) var(--g-spacing-md);
}

.year-toggle-wrapper {
    display: flex;
    justify-content: center;
    & button:first-child {
        margin-left: var(--g-spacing-xxs);
    }
    & button:last-child {
        margin-right: var(--g-spacing-xxs);
    }
}

.year-button {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: var(--g-spacing-xxs) 0;
    background-color: var(--s-color-selection-background-active);
    width: 100%;
    border: 1px solid transparent;
    border-radius: var(--c-switcher-button-border-radius);
    padding: var(--g-spacing-sm) 0;
    cursor: pointer;
    font-family: var(--g-font-family-primary);
    font-weight: var(--g-font-weight-regular);
    font-size: var(--g-font-size-300);
    color: var(--s-color-text-primary);
    gap: var(--g-spacing-sm);

    &:visited {
        color: inherit;
        text-decoration: none;
    }
    &:focus {
        outline-width: var(--g-border-width-xs);
        outline-color: var(--g-color-ink-black);
        outline-style: solid;
        outline-offset: calc(-1 * var(--g-spacing-xs));
    }
    &:active {
        outline: none;
    }
}

.year-button--active {
    background-color: var(--g-color-white);
    border-color: var(--g-color-ink-black);
    border-style: solid;
    border-width: var(--g-border-width-xs);
    box-shadow: var(--s-shadow-down);
}

.calendar-icon {
    display: flex;
}
