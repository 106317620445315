@use "@wojo/ui/mixins" as *;

.section {
    margin-bottom: var(--g-spacing-xl);

    @include breakpoint-md {
        margin-bottom: var(--g-spacing-xxl);
    }
}

.desktop {
    display: none;

    @include breakpoint-md {
        display: block;
    }
}

.mobile {
    display: block;

    @include breakpoint-md {
        display: none;
    }
}
